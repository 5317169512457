import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import PageBanner from "@/components/page-banner";
import AboutOne from "@/components/about-one";
import CallToActionOne from "@/components/call-to-action-one";
import TeamCarousel from "@/components/team-carousel";
import TestimonialsOneCarousel from "@/components/testimonials-carousel";
import FeatureTabOne from "@/components/feature-tab-1";
import HeaderOne from "@/components/header-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import SEO from "@/components/seo";

const AboutPage = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <SEO
          title="Soltriks - About Us"
          description="Learn more about Soltriks. We are a dedicated team of developers and cybersecurity experts committed to delivering innovative solutions for your business needs."
        />
        <Layout PageTitle="About Us">
          <HeaderOne />
          <PageBanner title="About Us" name="About" />
          <AboutOne />
          <TestimonialsOneCarousel />
          <TeamCarousel />
          <FeatureTabOne />
          <CallToActionOne extraClassName="ready" />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default AboutPage;
